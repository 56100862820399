import { createContext, useEffect, useRef, useCallback } from "react"
import { useLocation } from "react-router-dom"
import useBsStore from "~/store/bs"
import useUiStore from "~/store/ui\
"
import { eventsMap, evarsMap } from "~/constants/analytics"
import { forceBsUi, analyticsServerUrl } from '~/config'
import appInfo from '~/../package.json'

window.adobeDataLayer = window.adobeDataLayer || []

const reportSuiteId = (process.env.NODE_ENV !== 'production' || process.env.DEBUG)
  ? "facebookmetaglobaldev"
  : "facebookmetaglobalprod"
const sessionId = +new Date
const debugAnalytics = (new URL(document.location)).searchParams.has("debugAnalytics") || process.env.DEBUG
const AnalyticsContext = createContext()
const pageMap = {
  '/': 'Attract Loop',
  '/features': 'Home',
  '/features/capture': 'Features:Capture',
  '/features/capture/gallery': 'Features:Capture Lifestyle',
  '/features/share': 'Features:Share',
  '/features/share/gallery': 'Features:Share Lifestyle',
  '/features/listen': 'Features:Listen',
  '/features/listen/gallery': 'Features:Listen Lifestyle',
  '/features/specs': 'Tech Specs',
  '/features/demo': 'Demo',
}

const AnalyticsProvider = ({ children }) => {
  const sessionCt = useRef(-1)
  const { isBrightSign, deviceInfo, currentSync, networkName, measuredBootTime } = useBsStore(state => state)
  const guidedMode = useUiStore(state => state.guidedMode)
  const location = useLocation()
  const sessionStart = useRef(null)
  const engagementStart = useRef(null)
  const pageRef = useRef()
  const useBsAnalytics = (isBrightSign || forceBsUi) && process.env.DIST !== 'soho'
  const postInteraction = useBsAnalytics ? pushBsInteraction : pushWebInteraction

  useEffect(() => {
    if (isBrightSign) {
      window.BrightSignMessageExchange = new window.BSMessagePort()
      window.BrightSignMessageExchange.onbsmessage = handleBsEvent
      sendServerConfig({ adobeReportSuiteId: reportSuiteId, brand: "Ray-Ban", dataVersion: 1 })
    }
  }, [isBrightSign])

  const forceCurrentPageLoad = useCallback((bonus={}) => {
    postPageLoaded(pageMap[location.pathname], location.pathname, bonus)
  }, [location.pathname, deviceInfo.serialNumber]) // Avoid missing serial on first hit

  useEffect(() => {
    pageRef.current = pageMap[location.pathname]

    // Prevent pageLoaded events from firing at root on mobile (redirect to /features)
    if ((useBsAnalytics && sessionCt.current < 0) || (!useBsAnalytics && location.pathname === "/")) { return }
    postPageLoaded(pageRef.current, location.pathname)
  }, [location.pathname])

  async function sendServerConfig(c) {
    try {
      const r = await fetch(`${analyticsServerUrl}/v0/config`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(c),
      })
      const res = await r.json()
      console.log(res)
    } catch (e) {
      console.error("Error setting analytics config:")
      console.error(e)
    }
  }

  function getDate() {
    const local = new Date()
    const utc = +new Date
    const utcSeconds = Math.round(utc / 1000)
    return { local, utc, utcSeconds }
  }

  function mapEvars(data) {
    const obj = {}
    Object.keys(data).forEach(k => evarsMap[k] ? (obj[evarsMap[k]] = data[k]) : null)
    return obj
  }

  function postPageLoaded(pageName, pageUrl, bonus={}) {
    const { utc } = getDate()
    if (useBsAnalytics) {
      pushBsInteraction("pageLoad", { pageName, pageUrl, ...bonus })
    } else {
      const webPageDetails = {
        eventName: "pageLoad",
        pageName,
        name: pageName,
        pageUrl,
        eventDatetime: utc,
        mpCurrentDatetime: useBsAnalytics ? utc : null,
        deviceBrand: "Ray-Ban",
        deviceProduct: "Ray-Ban",
        deviceType: "QR Code",
        ...bonus,
      }
      if (guidedMode) { webPageDetails["mode"] = "Associate Guided" }
      const e = {
        event: "pageLoad",
        web: {
          webPageDetails
        }
      }
      if (debugAnalytics) { console.log(e) }
      window.adobeDataLayer.push(e)
    }
  }

  async function pushBsInteraction(name, data={}) {
    if (!sessionStart.current) { startSession() }
    const { utcSeconds } = getDate()
    const cid = `${(deviceInfo?.serialNumber||"TEST")}_${sessionId}_${sessionCt.current}`
    const eventName = name.split("=")[0]
    const numeric = name.split("=")[1] || false
    const AQ = {
      events: `event${eventsMap[eventName]}${numeric?`=${numeric}`:''}`,
      ...(mapEvars(data)),
      [evarsMap['deviceBrand']]: 'Ray-Ban',
      [evarsMap['deviceProduct']]: 'Ray-Ban',
      [evarsMap['deviceType']]: 'Touch Screen',
      [evarsMap['sessionId']]: cid,
    }
    if (eventName !== "pageLoad") { AQ["pe"] = "lnk_o" }
    const body = {
      cid,
      timestamp: utcSeconds,
      reportsuiteid: reportSuiteId,
      pageName: pageRef.current,
      AQ,
    }
    if (debugAnalytics) { console.log(body) }
    try {
      const r = await fetch(`${analyticsServerUrl}/v0/events`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
      const res = await r.text()
      if (debugAnalytics) { console.log(res) }
    } catch (e) {
      console.error(e)
    }
  }

  function pushWebInteraction(name, data={}) {
    const { utc } = getDate()
    const eventName = name.split("=")[0]
    const numeric = name.split("=")[1] || false
    const webInteraction = {
      eventName,
      ...data,
      eventDatetime: utc,
      mpCurrentDatetime: useBsAnalytics ? utc : null,
      deviceBrand: 'Ray-Ban',
      deviceProduct: 'Ray-Ban',
      deviceType: 'QR Code',
    }
    if (guidedMode) { webInteraction["mode"] = "Associate Guided" }
    const e = {
      event: eventName,
      web: {
        webInteraction
      }
    }
    if (debugAnalytics) { console.log(e) }
    window.adobeDataLayer.push(e)
  }

  function getTimezone() {
    const d = new Date()
    const dtf = Intl.DateTimeFormat(undefined, {timeZoneName: 'long'})
    return dtf.formatToParts(d).find((part) => part.type == 'timeZoneName').value
  }

  function incrementSession() {
    sessionCt.current += 1
  }

  function startSession() {
    incrementSession()
    sessionStart.current = +new Date

    const sessionVars = {
      mpSerialNumber: deviceInfo?.serialNumber,
      // mpIpAddress: null,  // TEMP: automatically filled by analytics server
      mpModel: deviceInfo?.model,
      mpUpdatedGroup: networkName,
      mpUpdateUrl: currentSync?.meta?.client?.base,
      mpFwVersion: deviceInfo?.osVersion,
      mpFwUpdateUrl: currentSync?.meta?.client?.base,
      mpAppVersion: appInfo.version,
      mpAppUpdateUrl: currentSync?.meta?.client?.base,
      mpCurrentDatetime: +new Date,
      mpBootDatetime: measuredBootTime,
      mpTimezone: getTimezone(),
      mpVolumeSetting: 0,
      deviceType: useBsAnalytics ? "Touch Screen" : "QR Code",
      deviceSize: (deviceInfo?.model && (deviceInfo.model === 'XD1034' ? '27"/42"' : '10"') || undefined),
      deviceMake: null,
      deviceBrand: 'Ray-Ban',
      deviceProduct: 'Ray-Ban',
      deviceCurrentVolume: 0,
      deviceDefaultVolume: 0,
      deviceMaxVolume: 0,
      deviceSerialNumber: null,
    }

    forceCurrentPageLoad(sessionVars)
    postInteraction("sessionStart")

  }

  function endSession() {
    postInteraction("sessionEnd")
    sessionStart.current = null
    engagementStart.current = null
  }

  function startEngagement() {
    engagementStart.current = +new Date
  }

  function handleBsEvent(e) {
    const m = e.data.message
    switch (m) {
      case "stella!ProductDown":
        if (sessionStart.current) { return postInteraction("deviceRest") }
        else { return null } // Don't trigger new session if we've already timed out
      case "stella!ProductUp":
        return postInteraction("deviceLift")
      default:
        return null
    }
  }

  return(
    <AnalyticsContext.Provider value={{
      postInteraction,
      sessionStart,
      startSession,
      startEngagement,
      engagementStart,
      endSession,
      forceCurrentPageLoad,
    }}>
      { children }
    </AnalyticsContext.Provider>
  )
}

export {
  AnalyticsContext,
  AnalyticsProvider,
}