const eventsMap = {
  "pageLoad": "1",
  "modalLoad": "2",
  "attractLoopStart": "3",
  "attractLoopEnd": "4",
  "navClick": "5",
  "touchToStart": "6",
  "videoStart": "7",
  "videoPlayed": "8",
  "passwordSubmitAttempt": "9",
  "passwordSubmitSuccess": "10",
  "demoStart": "11",
  "demoEnd": "12",
  "cameraOn": "13",
  "cameraOff": "14",
  "sessionStart": "15",
  "sessionResumed": "16",
  "sessionEnd": "17",
  "videoLength": "18",
  "error": "19",
  "video25": "20",
  "video50": "21",
  "video75": "22",
  "video100": "23",
  "demoDuration": "24",
  "sessionDuration": "25",
  "skipVideo": "26",
  "filterApplied": "27",
  "waiverAccepted": "28",
  "waiverRejected": "29",
  "surveySkipped": "30",
  "surveyAccepted": "31",
  "event32": "32",
  "privacyPolicyAccepted": "33",
  "privacyPolicyRejected": "34",
  "privacyPolicyViewed": "35",
  "languageChange": "36",
  "surveyStart": "37",
  "surveyQuestionAnswered": "38",
  "surveyComplete": "39",
  "castingOn": "40",
  "castingOff": "41",
  "bounceSession": "42",
  "engagedSession": "43",
  "scrubForward": "44",
  "scrubBackward": "45",
  "guidedModeOn": "46",
  "guidedModeOff": "47",
  "videoPaused": "48",
  "deviceLift": "49",
  "deviceRest": "50",
}

const evarsMap = {
  "country": "v1",
  "state": "v2",
  "city": "v3",
  "zipCode": "v4",
  "address": "v5",
  "retailerName": "v6",
  "storeId": "v7",
  "storeType": "v8",
  "displayType": "v9",
  "eVar10": "v10",
  "eVar11": "v11",
  "eVar12": "v12",
  "eVar13": "v13",
  "eVar14": "v14",
  "eVar15": "v15",
  "eVar16": "v16",
  "eVar17": "v17",
  "eVar18": "v18",
  "eVar19": "v19",
  "eVar20": "v20",
  "eVar21": "v21",
  "eVar22": "v22",
  "eVar23": "v23",
  "eVar24": "v24",
  "mpSerialNumber": "v25",
  "mpIpAddress": "v26",
  "mpModel": "v27",
  "mpUpdatedGroup": "v28",
  "mpUpdateUrl": "v29",
  "mpFwVersion": "v30",
  "mpFwUpdateUrl": "v31",
  "mpAppVersion": "v32",
  "mpAppUpdateUrl": "v33",
  "mpCurrentDatetime": "v34",
  "mpBootDatetime": "v35",
  "mpTimezone": "v36",
  "mpVolumeSetting": "v37",
  "deviceType": "v50",
  "deviceSize": "v51",
  "deviceMake": "v52",
  "deviceBrand": "v53",
  "deviceProduct": "v54",
  "deviceCurrentVolume": "v55",
  "deviceDefaultVolume": "v56",
  "deviceMaxVolume": "v57",
  "deviceSerialNumber": "v58",
  "eVar59": "v59",
  "eVar60": "v60",
  "eVar61": "v61",
  "eVar62": "v62",
  "eVar63": "v63",
  "eVar64": "v64",
  "eVar65": "v65",
  "eVar66": "v66",
  "eVar67": "v67",
  "eVar68": "v68",
  "eVar69": "v69",
  "eVar70": "v70",
  "eVar71": "v71",
  "eVar72": "v72",
  "eVar73": "v73",
  "eVar74": "v74",
  "eVar75": "v75",
  "eVar76": "v76",
  "eVar77": "v77",
  "eVar78": "v78",
  "eVar79": "v79",
  "eVar80": "v80",
  "eVar81": "v81",
  "eVar82": "v82",
  "eVar83": "v83",
  "eVar84": "v84",
  "eVar85": "v85",
  "eVar86": "v86",
  "eVar87": "v87",
  "eVar88": "v88",
  "eVar89": "v89",
  "eVar90": "v90",
  "eVar91": "v91",
  "eVar92": "v92",
  "eVar93": "v93",
  "eVar94": "v94",
  "eVar95": "v95",
  "eVar96": "v96",
  "eVar97": "v97",
  "eVar98": "v98",
  "eVar99": "v99",
  "eventTimestamp": "v100",
  "pageUrl": "v101",
  "pageName": "v102",
  "languageDefault": "v103",
  "languageCurrent": "v104",
  "accessibilityDefault": "v105",
  "accessibilityMenu": "v106",
  "mode": "v107",
  "sessionId": "v108",
  "eVar109": "v109",
  "eVar110": "v110",
  "eVar111": "v111",
  "eVar112": "v112",
  "eVar113": "v113",
  "eVar114": "v114",
  "eVar115": "v115",
  "eVar116": "v116",
  "eVar117": "v117",
  "eVar118": "v118",
  "eVar119": "v119",
  "eVar120": "v120",
  "eVar121": "v121",
  "eVar122": "v122",
  "eVar123": "v123",
  "eVar124": "v124",
  "navType": "v125",
  "navName": "v126",
  "videoName": "v127",
  "eVar128": "v128",
  "videoLength": "v129",
  "modalName": "v130",
  "muteSetting": "v131",
  "skipVideo": "v132",
  "demoName": "v133",
  "demoDuration": "v134",
  "demoLength": "v135",
  "arFilterCategory": "v136",
  "arFilterName": "v137",
  "errorName": "v138",
  "sessionDuration": "v139",
  "suveyName": "v140",
  "surveyQuestion": "v141",
  "surveyAnswer": "v142",
}

export {
  eventsMap,
  evarsMap,
}