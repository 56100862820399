import createStore from '~/utils/createStore'

const USER_VARS_PATH = 'http://localhost:8008/GetUserVars'
const USER_VARS_DEFAULT = {
  LanguageDefault: 'en',
  FrenchDialect: 'fr-CA',
  WithAda: '0',
  Interactive: '1',
}

const useStore = createStore((set, get) => ({
  isBrightSign: false,
  setIsBrightSign: (bool) => {
    set({ isBrightSign: bool })
  },

  userVars: USER_VARS_DEFAULT,
  fetchUserVars: async () => {
    try {
      const userVars = get().userVars
      await fetch(USER_VARS_PATH)
        .then(r => r.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then(data => {
          Array.from(data.querySelectorAll('BrightSignVar')).forEach(v => {
            userVars[v.getAttribute('name')] = v.textContent
          })
          set({ userVars })
          return userVars
        })
      return [userVars, null]
    } catch (error) {
      get().debug(error)
      return [null, error]
    }
  },

  deviceInfo: {},

  debugLog: [],
  debug: (msg) => {
    console.error(msg)
    const newLog = `${new Date().toISOString()} ${msg}`
    const currentLog = get().debugLog
    const debugLog = [newLog].concat(currentLog)
    debugLog.length = Math.min(debugLog.length, 50)
    set({ debugLog })
  },

  bsLib: {},
  setBsLib(bsLib) { set(bsLib) },
  currentSync: null,
  setCurrentSync(currentSync) { set(currentSync) },
  macAddress: '',
  internetStatus: '',
  uptime: '00:00:00:00',
  networkName: null,
  registry: null,
  measuredBootTime: null,

  initBs() {
    const {
      getRegistry,
      getCurrentSync,
      getDeviceInfo,
      testInternetConnectivity,
      debug,
    } = get()

    try {
      let Registry,
          System,
          NetworkDiagnostics,
          DeviceInfo;
      Registry = window.require("@brightsign/registry");
      System = window.require("@brightsign/system");
      NetworkDiagnostics = window.require("@brightsign/networkdiagnostics");
      DeviceInfo = window.require("@brightsign/deviceinfo");
      const bsLib = { Registry, System, NetworkDiagnostics, DeviceInfo }
      set({ bsLib })
      getDeviceInfo()
      getCurrentSync()
      getRegistry()
      testInternetConnectivity()
      set({ measuredBootTime: +new Date - get().deviceInfo.deviceUptime * 1000 })
    } catch (e) {
      debug(e)
      debug(`Failed to import BrightSign libraries`)
    }
  },

  getCurrentSync() {
    const { debug } = get()
    try {
      let fs = window.require('fs')
      let info = JSON.parse(fs.readFileSync('/storage/sd/current-sync.json'))
      set({currentSync: info})
    } catch (e) {
      debug(e)
      debug(`Failed to fetch current-sync.json`)
    }
  },

  getOSInfo() {
    const { debug } = get()
    try {
      const os = window.require('os')
      const info = os.networkInterfaces()
      let macAddress = 'Unable to fetch MAC Address'
      if (info) {
        const valids = Object.keys(info).filter(k => info[k].length && !info[k][0].internal)
        const macs = valids.map(device => `${device} = ${info[device][0].mac}`)
        macAddress = macs.join('  |  ')
      }
      set({macAddress})
    } catch (e) {
      debug(e)
    }
  },

  getDeviceInfo() {
    const { bsLib, getUptime } = get()
    const deviceInfo = new bsLib.DeviceInfo()
    set({ deviceInfo, isBrightSign: true })
    getUptime()
  },

  async getRegistry() {
    const { Registry } = get().bsLib
    const r = new Registry()
    const data = await r.read()
    set({ networkName: data.networking.a, registry: data })
  },

  getUptime() {
    const { deviceInfo } = get()
    const ut = deviceInfo.deviceUptime
    let leftover = ut
    const days = `${Math.floor(leftover / (60*60*24))}`.padStart(2, '0')
    leftover = leftover % (60*60*24)
    const hours = `${Math.floor(leftover / (60*60))}`.padStart(2, '0')
    leftover = leftover % (60*60)
    const minutes = `${Math.floor(leftover / (60))}`.padStart(2, '0')
    leftover = leftover % (60)
    const seconds = `${leftover}`.padStart(2, '0')
    const formatted = `${days}:${hours}:${minutes}:${seconds}`
    set({uptime: formatted})
  },

  async testInternetConnectivity() {
    const { bsLib, getOSInfo } = get()
    set({internetStatus: 'LOADING'})
    try {
      const network = new bsLib.NetworkDiagnostics()
      const data = await network.testInternetConnectivity()
      set({internetStatus: data.diagnosis})
    } catch(e) {
      set({internetStatus: e.message})
    } finally {
      getOSInfo()
    }
  },
}))

export default useStore