import '~/styles/FeatureSpecs.scss'
import { useState, useRef, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import gsap from 'gsap'
import FeatureProgressBar from '~/components/FeatureProgressBar'
import FeatureSequence from '~/components/FeatureSequence'
import useUiStore from '~/store/ui'
import useVideoStore from '~/store/video'
import useSequenceEvents from '~/hooks/useSequenceEvents'
import { splitLines } from '~/utils/splitText'
import { multiLine } from '~/utils/anims'
import { enableSequenceControls } from '~/config'

const SEGMENTS = [
  { name: 'Tech-1: Charging Case', start: 1, end: 5.5 },
  { name: 'Tech-2: Charging Case', start: 7.5, end: 15.25 },
  { name: 'Tech-3: Camera', start: 18, end: 32.5 },
]

const FeatureSpecs = () => {
  const { transDest, setAdaContent } = useUiStore(state => state)
  const [mountedSequence, setMountedSequence] = useState(false)
  const bgVid = useVideoStore(state => state.vidRef)
  const cnr = useRef()
  const progressRef = useRef()
  const tlRef = useRef()
  const c1 = useRef()
  const c2 = useRef()
  const safeToUnmount = useRef(false)
  const { t } = useTranslation()
  const { teardown, initSequence, play, pause, back, next } = useSequenceEvents(
    tlRef,
    bgVid,
    SEGMENTS,
    initTl,
    {
      endDest: '/features',
      vidSrcKey: 'specs',
    }
  )
  
  useEffect(() => {
    setAdaContent([{el:'h1',key:'features.specs.c1.title'}])
    return handleUnmount
  }, [])

  useEffect(() => {
    if (transDest) {
      teardown()
      setAdaContent([])
      safeToUnmount.current = true
    }
  }, [transDest])
  
  function handleUnmount() {
    if (!safeToUnmount.current) {
      teardown()
      setAdaContent([])
      safeToUnmount.current = true
    }
  }

  function initTl() {
    tlRef.current = gsap.timeline({ paused: true })
    const tl = tlRef.current
    const c1Title = c1.current.querySelector('.title')
    const c1Desc = splitLines(c1.current.querySelector('.description'))
    const c1List = c1.current.querySelector('ul')
    const c2g1 = c2.current.querySelector('.g1')
    const c2g2 = c2.current.querySelector('.g2')
    
    gsap.set(progressRef.current, { scaleX: 0 })
    
    tl.add(() => setAdaContent([{el:'h1',key:'features.specs.c1.title'},{el:'p',key:'features.specs.c1.description'}]), SEGMENTS[0].start)
    tl.fromTo(c1Title, { opacity: 0 }, { duration: 0.5, opacity: 1 }, SEGMENTS[0].start)
    tl.fromTo(c1Desc, multiLine.from, multiLine.to)
    tl.to(progressRef.current, { ease: "none", duration: SEGMENTS[0].end-SEGMENTS[0].start, scaleX: 0.333 }, SEGMENTS[0].start)
    tl.addLabel("c1:p1:in")
    tl.to(c1Desc, multiLine.out, SEGMENTS[0].end)
    tl.addLabel("c1:p1:out")
    tl.add(() => setAdaContent([{el:'h1',key:'features.specs.c1.title'},{el:'p',key:'features.specs.c1.list',break:'<div/>'}]), SEGMENTS[1].start)
    tl.fromTo(c1List, { opacity: 0 }, { duration: 0.5, opacity: 1 }, SEGMENTS[1].start)
    tl.to(progressRef.current, { ease: "none", duration: SEGMENTS[1].end-SEGMENTS[1].start, scaleX: 0.666 }, SEGMENTS[1].start)
    tl.addLabel("c1:p2:in")
    tl.to(c1.current, { duration: 0.5, opacity: 0 }, SEGMENTS[1].end)
    tl.addLabel("c1:p2:out")
    tl.add(() => setAdaContent([
      {el:'h1',key:'features.specs.c2.g1.title'},{el:'p',key:'features.specs.c2.g1.list',break:'<div/>'},
      {el:'h1',key:'features.specs.c2.g2.title'},{el:'p',key:'features.specs.c2.g2.list',break:'<div/>'},
    ]), SEGMENTS[2].start)
    tl.fromTo(c2g1, { opacity: 0 }, { duration: 0.5, opacity: 1 }, SEGMENTS[2].start)
    tl.fromTo(c2g2, { opacity: 0 }, { duration: 0.5, opacity: 1 })
    tl.to(progressRef.current, { ease: "none", duration: SEGMENTS[2].end-SEGMENTS[2].start, scaleX: 1 }, SEGMENTS[2].start)
    tl.addLabel("c2:in")
    tl.to(c2.current, { duration: 0.5, opacity: 0 }, SEGMENTS[2].end)
    tl.addLabel("c2:out")
  }

  function init() {
    initSequence()
    setMountedSequence(true)

    return(() => {
      tlRef.current.kill()
      setMountedSequence(false)
    })
  }
  
  return(
    <>
      <FeatureSequence action={init} play={play} pause={pause} back={back} next={next} control={enableSequenceControls}>
        <div id="FeatureSpecs" ref={cnr} style={{opacity: mountedSequence ? 1 : 0}}>
          <div className="content-overlay c1" ref={c1}>
            <div className="g1">
              <h1 className="title">{t('features.specs.c1.title')}</h1>
              <p className="description">{t('features.specs.c1.description')}</p>
              <ul>
                <Trans
                  defaults="<l>Put the glasses in the case to begin charging</l><l>USB-C powered</l><l>Green LED means charged</l><l>Orange LED means charging</l>"
                  components={{ l: <li/> }}
                  i18nKey="features.specs.c1.list"
                />
              </ul>
            </div>
          </div>
          <div className="content-overlay c2" ref={c2}>
            <div className="g1">
              <h2 className="title">{t('features.specs.c2.g1.title')}</h2>
              <ul>
                <Trans
                  defaults="<l>2D Field of View: 105 degrees for Stereoscopic depth</l><l>Auto adjusts to the light for consistently high quality images</l><l>Photos: 2592 px X 1944 px</l><l>Videos: 1184 px X 1184 px @ 30 FPS</l>"
                  components={{ l: <li/> }}
                  i18nKey="features.specs.c2.g1.list"
                />
              </ul>
            </div>
            <div className="g2">
              <h2 className="title">{t('features.specs.c2.g2.title')}</h2>
              <ul>
                <Trans
                  defaults="<l>2x micro speakers</l><l>3 beamforming microphone audio array</l>"
                  components={{ l: <li/> }}
                  i18nKey="features.specs.c2.g2.list"
                />
              </ul>
            </div>
          </div>
        </div>
      </FeatureSequence>
      <FeatureProgressBar numSegments={3} ref={progressRef} />
    </>
  )
}

export default FeatureSpecs