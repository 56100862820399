import '~/styles/ErrorOverlays.scss'

const ErrorOverlays = () => {
  return(
    <>
      <div className="error-overlay is-landscape">
        <h1>Please rotate your device to portrait orientation.</h1>
      </div>
    </>
  )
}

export default ErrorOverlays