import '~/styles/LifestyleGallery.scss'
import { useEffect, useState, useRef } from 'react'
import useUiStore from '~/store/ui'
import useVideoStore from '~/store/video'
import useBsStore from '~/store/bs'
import useLifestyleEvents from '~/hooks/useLifestyleEvents'
import LifestyleGalleryItem from '~/components/LifestyleGalleryItem'
import PlayButton from '~/components/PlayButton'
import Portal from '~/components/Portal'

const BLACKOUT_MS = 500

const LifestyleGallery = ({ imgSrcs, vidSrcKeys, analyticsKeys, template }) => {
  const { setAttractTimeoutActive, adaPortalRef,
          setHideUi, openLoader, teardownLoader } = useUiStore(state => state)
  const { setKey: setVidSrcKey, key: currVidSrcKey,
          vidRef: bgVid } = useVideoStore(state => state)
  const isBrightSign = useBsStore(state => state.isBrightSign)
  const closeTmt = useRef()
  const transTmt = useRef()
  const active = useRef(false)
  const [mounted, setMounted] = useState(false)
  const [viewing, setViewing] = useState(false)
  const [selectedSrc, setSelectedSrc] = useState(null)
  const [closing, setClosing] = useState(false)
  const [showPanels, setShowPanels] = useState(true)
  const transMs = 330 + 100 * imgSrcs.length // account for ImgLazy transition + number of imgs
  const { bindVideoAnalytics, cancelVideoAnalytics, finVideoAnalytics } = useLifestyleEvents(bgVid.current)

  useEffect(() => {
    initVideo()
    setMounted(true)
    setAttractTimeoutActive(true)
    return(() => {
      bgVid.current.oncanplaythrough = null
      bgVid.current.onended = null
      cancelVideoAnalytics(bgVid.current)
      setAttractTimeoutActive(false)
      clearTimeout(closeTmt.current)
      clearTimeout(transTmt.current)
      setHideUi(false)
    })
  }, [])

  function initVideo() {
    bgVid.current.oncanplaythrough = onCanPlayThrough
    bgVid.current.onended = onEnded
  }

  function onPlay(e) {
    if (active.current) {
      teardownLoader()
      setViewing(true)
    }
  }

  function onCanPlayThrough(e) {
    bgVid.current.onplay = onPlay
    e.target.play()
  }

  function onEnded(e) {
    clearTimeout(closeTmt.current)
    close()
  }

  function open(src) {
    if (active.current) { return }
    active.current = true
    clearTimeout(transTmt.current)
    clearTimeout(closeTmt.current)
    
    setAttractTimeoutActive(false)
    setShowPanels(false)
    
    setSelectedSrc(src)
    bindVideoAnalytics(bgVid.current, analyticsKeys[vidSrcKeys.findIndex(s => s === src)])
    transTmt.current = setTimeout(() => {
      if (!isBrightSign) {openLoader()}
      setVidSrcKey(src)
      setHideUi(true)
    }, transMs)
  }

  function close() {
    active.current = false
    clearTimeout(closeTmt.current)

    setAttractTimeoutActive(true)
    setViewing(false)
    setClosing(true)

    closeTmt.current = setTimeout(() => {
      if (bgVid.current.currentTime < bgVid.current.duration) {
        finVideoAnalytics(bgVid.current)
      }
      cancelVideoAnalytics(bgVid.current)
      setSelectedSrc(null)
      setVidSrcKey(null)
      setShowPanels(true)
      setHideUi(false)
      transTmt.current = setTimeout(() => {
        setClosing(false)
      }, transMs)
    }, BLACKOUT_MS)
    
  }
  
  const panelsOn = mounted && showPanels
  const pickerActive = !viewing && !closing

  return(
    <>
      <div
        className="blackout"
        style={{
          opacity: viewing ? 0 : 1,
          transition: `opacity ${BLACKOUT_MS}ms ease`,
        }}
      ></div>
      <div
        className={"lifestyle-gallery"}
        style={{
          display: 'grid',
          gridTemplate: template,
          opacity: mounted ? 1 : 0,
          overflowX: 'hidden',
        }}>
        { imgSrcs.map((s,i,a) => {
          const active = vidSrcKeys[i] === selectedSrc
          const delay = 
            active
            ? (a.length - 1) * 0.1 + 0.2
            : i*0.1 + 0.1
          return (
            <LifestyleGalleryItem
              key={i}
              onClick={() => pickerActive ? open(vidSrcKeys[i]) : null}
              delay={delay}
              visible={panelsOn}
              alt={`Lifestyle ${i+1}`}
              src={imgSrcs[i]}
              active={active&&pickerActive}
            />
        )}) }
        { viewing &&
          <span className="video-close" onClick={e => close()}>
            <svg width="100" height="100" viewBox="0 0 100 100">
              <line x1="3" y1="3" x2="97" y2="97" stroke="white" strokeWidth="6" />
              <line x1="3" y1="97" x2="97" y2="3" stroke="white" strokeWidth="6" />
            </svg>
          </span> }
      </div>
      { mounted && adaPortalRef &&
        <Portal rootNode={adaPortalRef.current}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `1fr 1fr`,
              gap: '0.5em',
            }}>
          { viewing
            ? <span onClick={e => close()}>
                <svg width="2em" height="2em" viewBox="0 0 100 100">
                  <line x1="3" y1="3" x2="97" y2="97" stroke="white" strokeWidth="6" />
                  <line x1="3" y1="97" x2="97" y2="3" stroke="white" strokeWidth="6" />
                </svg>
              </span>
            : null
          }
          { vidSrcKeys.map((v,i) => {
            const active = vidSrcKeys[i] === selectedSrc
            return (
              <span
                key={v}
                className="play-button"
                onClick={() => pickerActive ? open(vidSrcKeys[i]) : null}
                style={{
                  width: '100%',
                  height: '4em',
                  display: viewing ? active ? 'inline-flex' : 'none' : 'inline-flex',
                  backgroundImage: `url(${imgSrcs[i]})`,
                  backgroundSize: 'cover',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                { !viewing && <PlayButton width="100%" height="100%" active={active&&pickerActive} /> }
              </span>
            )} ) }
          </div>
        </Portal> }
    </>
  )
}

export default LifestyleGallery