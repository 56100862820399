import '~/styles/FeaturesHome.scss'
import { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import bg from 'IMAGE_FeaturesHome'
import useUiStore from '~/store/ui'
import useVideoStore from '~/store/video'

const FeaturesHome = forwardRef(({navRef}, ref) => {
  const { t } = useTranslation()
  const { setAdaContent, setAttractTimeoutActive, teardownLoader, appBounds } = useUiStore(state => state)
  const setVidSrcKey = useVideoStore(state => state.setKey)
  const [bottom, setBottom] = useState('auto')
  
  useEffect(() => {
    teardownLoader()
    setVidSrcKey('')
    setAdaContent([
      {el: 'h1', key: 'features.home.title'},
      {el: 'p', key: 'features.home.description'},
    ])
    setAttractTimeoutActive(true)
    return(() => {
      setAdaContent([])
      setAttractTimeoutActive(false)
    })
  }, [])

  useEffect(() => {
    reposition()
  }, [appBounds])

  function reposition() {
    const navDims = navRef.current.getBoundingClientRect()
    const newBottom = `calc(${appBounds.h - parseInt(navDims.top)}px + 5.25em)`
    setBottom(newBottom)
  }

  return(
    <div ref={ref} id="FeaturesHome">
      <div className="bg-layer">
        <img alt="Features" src={bg} />
      </div>
      <div className="content" style={{ bottom }}>
        <h1 className="title">{t('features.home.title')}</h1>
        <div className="description">{t('features.home.description')}</div>
      </div>
      <span className="black" style={{background: '#000', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,}} />
    </div>
  )
})

export default FeaturesHome