import '~/styles/ADAToggle.scss'
import useUiStore from '~/store/ui'

const ADAToggle = () => {
  const setAdaPanelOpen = useUiStore(state => state.setAdaPanelOpen)
  const hideUi = useUiStore(state => state.hideUi)
  return(
    <div
      id="ADAToggle"
      onClick={_ => hideUi ? null : setAdaPanelOpen(true)}
      style={{
        opacity: hideUi ? 0 : 1,
        transition: `opacity 0.33s ease`
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.69 87.39">
        <title>Toggle ADA</title>
        <path d="M19.1,35.32A25.79,25.79,0,1,0,53.53,61.37" fill="none" stroke="#fcfcfc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px" />
        <path d="M29.81,19.11a9.13,9.13,0,1,0-13.57-7.79" fill="none" stroke="#fcfcfc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px" />
        <path d="M18.69,18.76,35.8,48.5A10.74,10.74,0,0,0,45.13,54H59.56a10.74,10.74,0,0,1,9.63,6l6.5,13.22" fill="none" stroke="#fcfcfc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px" />
      </svg>
    </div>
  )
}

export default ADAToggle