function PlayButton({width, height, active}) {
  return(
    <svg height="2rem" width="2rem" version="1.1" x="0px" y="0px" viewBox="0 0 100 100">
      <path fill="rgba(255,255,255,0.9)" d="M65.8,47.5L42.7,32.1c-2-1.3-4.6,0.1-4.6,2.5v30.8c0,2.4,2.7,3.8,4.6,2.5l23.1-15.4C67.6,51.3,67.6,48.7,65.8,47.5z"/>
      <circle cx="50" cy="50" r="47.5" strokeWidth="5" stroke="rgba(255,255,255,0.9)" fill="transparent" />
      <g className="highlight" style={{opacity: active ? 1 : 0}}>
        <path fill="rgba(205, 8, 20, 1)" d="M65.8,47.5L42.7,32.1c-2-1.3-4.6,0.1-4.6,2.5v30.8c0,2.4,2.7,3.8,4.6,2.5l23.1-15.4C67.6,51.3,67.6,48.7,65.8,47.5z"/>
        <circle cx="50" cy="50" r="47.5" strokeWidth="6" stroke="rgba(205, 8, 20, 1)" fill="transparent" />
      </g>
    </svg>
  )
}

export default PlayButton