import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import contentEn from "~/constants/content-en.json"
import contentDe from "~/constants/content-de.json"
import contentEs from "~/constants/content-es.json"
import contentIt from "~/constants/content-it.json"
import contentFr from "~/constants/content-fr.json"
import contentFrCa from "~/constants/content-fr-CA.json"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      'en': { translation: contentEn },
      'es': { translation: contentEs },
      'it': { translation: contentIt },
      'fr': { translation: contentFr },
      'fr-CA': { translation: contentFrCa },
    },
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (format === 'b') return `<strong>${value}</strong>`;
        return value;
      },
    }
  });