import '~/styles/BSOverlay.scss'
import { useState, useEffect, useRef, useContext } from 'react'
import useUiStore from '~/store/ui'
import useBsStore from '~/store/bs'
import BSOverlayAccess from '~/components/BSOverlayAccess'
import usePrevious from '~/hooks/usePrevious'
import { analyticsServerUrl } from '~/config'
import { AnalyticsContext } from '~/context/Analytics'
import appInfo from '~/../package.json'

const BSOverlay = () => {
  const isMount = useRef(true)
  const [currentTime, setCurrentTime] = useState(new Date())
  const [analyticsSyncing, setAnalyticsSyncing] = useState(false)
  const [analyticsSyncMessage, setAnalyticsSyncMessage] = useState('')
  const [access, setAccess] = useState(false)
  const [allowReboot, setAllowReboot] = useState(true)
  const { postInteraction } = useContext(AnalyticsContext)
  const uptimeTmt = useRef()
  const currentTimeTmt = useRef()
  const closeTmt = useRef()
  const {
    setShowBSOverlay,
    setRenderAda,
    renderAda,
    defaultLanguage,
    setDefaultLanguage,
    frenchDialect,
    setFrenchDialect,
    interactive,
    setInteractive,
  } = useUiStore(state => state)
  const {
    debug,
    debugLog,
    bsLib: BrightSign,
    currentSync,
    deviceInfo,
    macAddress,
    uptime,
    getUptime,
    internetStatus,
    testInternetConnectivity,
    getCurrentSync,
  } = useBsStore(state => state)
  const { serialNumber, osVersion } = deviceInfo
  const prevDefaultLanguage = usePrevious(defaultLanguage)
  const prevRenderAda = usePrevious(renderAda)
  const prevFrenchDialect = usePrevious(frenchDialect)
  const prevInteractive = usePrevious(interactive)

  useEffect(() => {
    postInteraction("modalLoad", { modalName: "Admin" })
    getCurrentSync()
    getCurrentTime()
    testInternetConnectivity()
    setCloseTmt()
    window.addEventListener('pointerdown', setCloseTmt)

    return () => {
      clearTimeout(uptimeTmt.current)
      clearTimeout(currentTimeTmt.current)
      clearTimeout(closeTmt.current)
      window.removeEventListener('pointerdown', setCloseTmt)
    }
  }, [])

  useEffect(() => {
    uptimeTmt.current = setTimeout(getUptime, 1000)
  }, [uptime])

  useEffect(() => {
    if (isMount.current) { return isMount.current = false }
    if (
      (defaultLanguage !== prevDefaultLanguage) ||
      (renderAda !== prevRenderAda) ||
      (frenchDialect !== prevFrenchDialect) ||
      (interactive !== prevInteractive)
    ) {
      postUserVars()
    }
  }, [
    defaultLanguage, prevDefaultLanguage,
    renderAda, prevRenderAda,
    frenchDialect, prevFrenchDialect,
    interactive, prevInteractive,
  ])

  function setCloseTmt() {
    clearTimeout(closeTmt.current)
    closeTmt.current = setTimeout(() => setShowBSOverlay(false), 1000*60*5) // Exit after 5 minutes inactivity
  }

  function getCurrentTime() {
    setCurrentTime(new Date())
    currentTimeTmt.current = setTimeout(getCurrentTime, 1000)
  }

  async function postUserVars() {
    setAllowReboot(false)
    const formData = new FormData()
    formData.append('LanguageDefault', defaultLanguage)
    formData.append('FrenchDialect', frenchDialect)
    formData.append('WithAda', renderAda ? "1" : "0")
    formData.append('Interactive', interactive ? "1" : "0")
    fetch('http://localhost:8008/SetValues', {
      method: 'POST',
      body: formData,
    }).then(r => {
      setAllowReboot(true)
    }).catch(e => {
      debug(`Could not update User Vars: ${e.message}`)
      setAllowReboot(true)
    })
  }

  function reboot() {
    const sys = new BrightSign.System()
    sys.reboot()
  }

  function formatDateUpdated(lastModifiedTime) {
    const d = new Date(lastModifiedTime)
    d.setMinutes(d.getMinutes() - d.getTimezoneOffset()) // Compensate for missing timezone in current-sync.json date...
    return d.toUTCString()
  }

  async function syncAnalytics() {
    setAnalyticsSyncing(true)
    setAnalyticsSyncMessage('')
    try {
      const r = await fetch(`${analyticsServerUrl}/v0/sync/adobe`, { method: 'POST' })
      const res = await r.text()
      setAnalyticsSyncMessage(res)
    } catch(e) {
      setAnalyticsSyncMessage(e.message)
    } finally {
      setAnalyticsSyncing(false)
    }
  }

  return(
    <div id="BSOverlay">
    {
      access
      ? <div className="stats">
          <section className="debug">
            <h2 className="title">Debug Info</h2>
            <ul className="info">
              <li className="serialNumber"><strong>Media Player Serial Number: </strong> {serialNumber}</li>
              <li className="osVersion"><strong>Media Player FW Version: </strong> {osVersion}</li>
              <li className="currentTime"><strong>Current Time:</strong> {currentTime.toUTCString()}</li>
              <li className="uptime"><strong>Uptime:</strong> {uptime}</li>
              <li className="buildVersion"><strong>Build Version:</strong> {appInfo.version}</li>
              <li className="updateDate"><strong>Date Updated:</strong> {formatDateUpdated(currentSync?.meta.client.lastModifiedTime)}</li>
              <li className="updateURL"><strong>Update URL:</strong> {currentSync?.meta.client.base}</li>
              <li className="macAddress"><strong>MAC Address:</strong> {macAddress}</li>
              <li className="internetStatus">
                <strong>Internet Connection Status:</strong> {internetStatus}
                { internetStatus !== 'LOADING' &&
                  <>
                    <br/>
                    <button style={{marginTop:'1em'}} onClick={testInternetConnectivity}>Test Connectivity</button>
                  </>
                }
              </li>
            </ul>
          </section>
          <section>
            <h2 className="title">Analytics</h2>
            <div className="info">
              <button onClick={syncAnalytics} disabled={analyticsSyncing} style={{marginTop:'1em'}}>Sync Events</button>
              <p>{analyticsSyncMessage}</p>
            </div>
          </section>
          <section>
            <h2 className="title">System Control</h2>
            <ul className="info">
              <li className="radio-list language">
                Default Language:
                <label>
                  <input
                    type="radio"
                    value="en"
                    checked={defaultLanguage === 'en'}
                    onChange={e => setDefaultLanguage(e.target.value)}
                  />
                  English
                </label>
                <label>
                  <input
                    type="radio"
                    value="it"
                    checked={defaultLanguage === 'it'}
                    onChange={e => setDefaultLanguage(e.target.value)}
                  />
                  Italiano
                </label>
                <label>
                  <input
                    type="radio"
                    value={frenchDialect}
                    checked={defaultLanguage === frenchDialect}
                    onChange={e => setDefaultLanguage(e.target.value)}
                  />
                  Français
                </label>
                <label>
                  <input
                    type="radio"
                    value="es"
                    checked={defaultLanguage === 'es'}
                    onChange={e => setDefaultLanguage(e.target.value)}
                  />
                  Español
                </label>
              </li>
              <li className="radio-list french">
                French Dialect:
                <label>
                  <input
                    type="radio"
                    value="fr"
                    checked={frenchDialect === 'fr'}
                    onChange={e => setFrenchDialect(e.target.value)}
                  />
                  France
                </label>
                <label>
                  <input
                    type="radio"
                    value="fr-CA"
                    checked={frenchDialect === 'fr-CA'}
                    onChange={e => setFrenchDialect(e.target.value)}
                  />
                  Québec
                </label>
              </li>
              <li className="radio-list ada">
                Accessibility Menu:
                <label>
                  <input
                    type="radio"
                    value="1"
                    checked={renderAda}
                    onChange={e => setRenderAda(!!parseInt(e.target.value))}
                  />
                  On
                </label>
                <label>
                  <input
                    type="radio"
                    value="0"
                    checked={!renderAda}
                    onChange={e => setRenderAda(!!parseInt(e.target.value))}
                  />
                  Off
                </label>
              </li>
              <li className="radio-list interactive">
                Interactive:
                <label>
                  <input
                    type="radio"
                    value="1"
                    checked={interactive}
                    onChange={e => setInteractive(!!parseInt(e.target.value))}
                  />
                  On
                </label>
                <label>
                  <input
                    type="radio"
                    value="0"
                    checked={!interactive}
                    onChange={e => setInteractive(!!parseInt(e.target.value))}
                  />
                  Off
                </label>
              </li>
              <li className="reboot"><button style={{opacity: allowReboot?1:0.5}} disabled={!allowReboot} onClick={reboot}>Reboot</button></li>
            </ul>
          </section>
          { !!debugLog.length && <div className="errors">{debugLog.map((e,i) => <div key={i}>{e}</div>)}</div> }
        </div>
      : <BSOverlayAccess
          onSuccess={() => {
            postInteraction("passwordSubmitSuccess")
            setAccess(true)
          }}
        />
    }
    <span className="close" onClick={e => setShowBSOverlay(false)}>
      <svg width="100" height="100" viewBox="0 0 100 100">
        <line x1="3" y1="3" x2="97" y2="97" stroke="white" strokeWidth="6" />
        <line x1="3" y1="97" x2="97" y2="3" stroke="white" strokeWidth="6" />
      </svg>
    </span>
    </div>
  )
}

export default BSOverlay