import '~/styles/Features.scss'
import { useState, useEffect, useRef } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import FeaturesNav from '~/components/FeaturesNav'
import FeaturesNavMobile from '~/components/FeaturesNavMobile'
import FeaturesHome from '~/components/FeaturesHome'
import FeatureCapture from '~/components/FeatureCapture'
import FeatureListen from '~/components/FeatureListen'
import FeatureShare from '~/components/FeatureShare'
import FeatureSpecs from '~/components/FeatureSpecs'
import Demo from '~/components/Demo'
import LifestyleCapture from '~/components/LifestyleCapture'
import LifestyleListen from '~/components/LifestyleListen'
import LifestyleShare from '~/components/LifestyleShare'
import { multiLine } from '~/utils/anims'
import useUiStore from '~/store/ui'
import useBsStore from '~/store/bs'
import useVideoStore from '~/store/video'
import usePrevious from '~/hooks/usePrevious'

import bgImgSrc from '~/assets/images/home-bg.png'
import { useMediaQuery } from 'react-responsive'
import { mobileQuery, forceBsUi } from '~/config'

const BG_PRE = document.createElement('img')

const Features = () => {
  const [navActive, setNavActive] = useState(false)
  const [hideNavLabels, setHideNavLabels] = useState(true)
  const isMobile = useMediaQuery({ query: mobileQuery, options: { noSsr: true } })
  const [transing, setTransing] = useState(false)
  const navRef = useRef()
  const homeRef = useRef()
  const transRef = useRef()
  const splitDescRef = useRef()
  const location = useLocation()
  const tlRef = useRef(gsap.timeline({ paused: true }))
  const transTlRef = useRef(gsap.timeline({ paused: true }))
  const transTlCleanup = useRef(() => null)
  const setVidSrcKey = useVideoStore(state => state.setKey)
  const vidSrcKey = useVideoStore(state => state.key)
  const vidRef = useVideoStore(state => state.vidRef)
  const history = useHistory()
  const { i18n } = useTranslation()
  const prevLanguage = usePrevious(i18n.language)
  const attractTimeout = useRef(null)
  const { isBrightSign } = useBsStore(state => state)
  const {
    transDest,
    setTransDest,
    changingLanguage,
    attractTimeoutDur,
    guidedMode,
    attractTimeoutActive,
    setAttractTimeoutActive,
    pulseAttractTimeout,
    interactive,
  } = useUiStore(state => state)
  const useBsUi = isBrightSign || forceBsUi

  useEffect(() => {
    if (location.pathname === '/features') {
      initHome()
    } else {
      setNavActive(true)
    }

    const unlisten = history.listen(historyListener)

    window.addEventListener('click', pulseAttractTimeout)
    window.addEventListener('touchend', pulseAttractTimeout)
    
    return(() => {
      unlisten()
      clearTimeout(attractTimeout.current)
      window.removeEventListener('click', pulseAttractTimeout)
      window.removeEventListener('touchend', pulseAttractTimeout)
      BG_PRE.removeEventListener('load', enter)
      tlRef.current.kill()
    })
  }, [])

  useEffect(() => {
    clearTimeout(attractTimeout.current)
    if (attractTimeoutActive && useBsUi) {
      attractTimeout.current = setTimeout(() => setTransDest('/timeout'), attractTimeoutDur)
    }
  }, [attractTimeoutActive, useBsUi])

  useEffect(() => {
    if (transDest) {
      setAttractTimeoutActive(false)
      if (transDest === '/' || transDest === '/timeout') {
        visitAttract()
      } else {
        if (transDest === '/features' && location.pathname === '/features') {
          cancelVisitFromHome()
        } else if (location.pathname === '/features') {
          visitFeatureFromHome(transDest)
        } else if (transDest === '/features') {
          visitHomeFromFeature()
        } else {
          visitFeatureFromFeature(transDest)
        }
      }
    }
  }, [transDest])

  useEffect(() => {
    if (changingLanguage && splitDescRef.current) {
      splitDescRef.current.revert()
    }
  }, [changingLanguage])

  useEffect(() => {
    if (prevLanguage && location.pathname === '/features') {
      let tlNow = tlRef.current.time()
      tlRef.current.kill()
      initHomeTimeline()
      tlRef.current.seek(tlNow)
      tlRef.current.tweenTo('enter')
    }
  }, [i18n.language])

  useEffect(() => {
    if (!interactive) {
      visitAttract()
    }
  }, [interactive])

  function historyListener(loc) {
    if (history.action === 'POP') {
      if (loc.pathname === '/features') {
        tlRef.current.kill()
        initHomeTimeline(false)
        tlRef.current.tweenTo('enter')
      } else {
        tlRef.current.tweenTo('exit', { onComplete() {
          setHideNavLabels(true)
          setTransDest(null)
        } })
      }
    }
  }

  function initHomeTimeline(fullReset=true) {
    tlRef.current = gsap.timeline({ paused: true })
    const tl = tlRef.current

    // Create references
    const bg = homeRef.current.querySelector('.bg-layer')
    const black = homeRef.current.querySelector('.black')
    const title = homeRef.current.querySelector('.title')
    const links = Array.from(navRef.current.querySelectorAll('.link'))
    const labels = Array.from(navRef.current.querySelectorAll('.link .label'))
    const linkOutlines = Array.from(navRef.current.querySelectorAll('.link > .outline'))
    const iconOutlines = Array.from(navRef.current.querySelectorAll('.icon .outline'))
    
    splitDescRef.current = new SplitText(homeRef.current.querySelector('.description'), {type: "lines"})
    
    // Construct timeline
    tl.fromTo(bg, { opacity: 0 }, { duration: 0.33, opacity: 1 })
    tl.fromTo(black, { opacity: 1 }, { duration: 0.33, opacity: 0 })
    
    let currentDur = tl.duration()
    tl.fromTo(title, { opacity: 0, y: -10 }, { duration: 0.33, opacity: 1, y: 0 }, currentDur)
    tl.fromTo(
      splitDescRef.current.lines,
      multiLine.from,
      multiLine.to,
      "-=0.22"
    )
    
    tl.add(() => setNavActive(true))
    
    if (isMobile && fullReset) {
      tl.fromTo(
        links,
        { opacity: 0, y: 10, transformOrigin: "50% 0%" },
        { duration: 0.5, opacity: 1, y: 0, stagger: 0.1 },
        currentDur - 0.5
      )
    } else if (isMobile) {
      /* ... */
    } else if (fullReset) {
      setHideNavLabels(false)
      tl.fromTo(
        links,
        { opacity: 0, y: -10, transformOrigin: "50% 0%" },
        { duration: 0.33, opacity: 1, y: 0, stagger: 0.1 },
        "-=0.33"
      )
    } else {
      let charsIn = tl.duration() - 0.33
      tl.fromTo(labels, {opacity: 0}, { duration: 0.33, opacity: 1, stagger: 0.1 }, charsIn)
      tl.fromTo(linkOutlines, {opacity: 0}, { duration: 0.33, opacity: 1, stagger: 0.1 }, charsIn)
      tl.fromTo(iconOutlines, {opacity: 1}, { duration: 0.33, opacity: 0, stagger: 0.1 }, charsIn)
      setHideNavLabels(false)
    }
      
    currentDur = tl.duration()
    tl.addLabel("enter")

    tl.to(title, { duration: 0.33, opacity: 0, y: 10 })
    tl.to(splitDescRef.current.lines, multiLine.outTB, '-=0.33')

    if (isMobile) {
      /* ... */
    } else if (fullReset) {
      tl.fromTo(labels, { opacity: 1 }, { duration: 0.33, opacity: 0, stagger: 0.1 }, currentDur)
      tl.fromTo(linkOutlines, { opacity: 1 }, { duration: 0.33, opacity: 0, stagger: 0.1 }, currentDur)
      tl.fromTo(iconOutlines, { opacity: 0 }, { duration: 0.33, opacity: 1, stagger: 0.1 }, currentDur)
    }
    else {
      tl.to(labels, { duration: 0.33, opacity: 0, stagger: 0.1 }, currentDur)
      tl.to(linkOutlines, { duration: 0.33, opacity: 0, stagger: 0.1 }, currentDur)
      tl.to(iconOutlines, { duration: 0.33, opacity: 1, stagger: 0.1 }, currentDur)
    }
    tl.to(bg, { duration: 0.33, opacity: 0 })
    tl.addLabel("exit")

    window._TL = tl
  }

  function initHome() {
    initHomeTimeline()
    BG_PRE.addEventListener('load', enter)
    BG_PRE.src = bgImgSrc
  }
  
  function enter() {
    tlRef.current.tweenTo("enter")
  }

  function transTo(path) {
    setTransDest(path)
  }

  function visitAttract() {
    tlRef.current.kill()
    const tl = transTlRef.current

    function onTransEnd() {
      vidRef.current.removeEventListener('ended', onTransEnd)
      setTransDest(null)
      history.push('/')
    }

    tl.to(transRef.current, { duration: 0.5, opacity: 1 })
    tl.to(navRef.current.querySelectorAll('.link'), { duration: 0.33, opacity: 0, stagger: -0.1 }, '-=0.25')
    transTlCleanup.current = () => {
      tl.kill()
      if (vidSrcKey) {
        setVidSrcKey('')
        vidRef.current.addEventListener('ended', onTransEnd)
      } else {
        setTransDest(null)
        history.push('/')
      }
    }
    tl.add(() => transTlCleanup.current())
    tl.play()
  }

  function visitFeatureFromFeature(path) {
    tlRef.current.kill()
    transTlRef.current.kill()
    const tl = gsap.timeline({ paused: true })
    const trans = transRef.current

    function onTransEnd() {
      vidRef.current.removeEventListener('ended', onTransEnd)
      setTransDest(null)
      history.push(path)
      tl.tweenTo('finished')
      setTransing(false)
    }

    if (transTlRef.current.isActive()) { handleCancelMidTimeout() }

    vidRef.current.removeEventListener('ended', onTransEnd)

    tl.to(trans, { duration: 0.33, opacity: 1 })
    tl.add(() => {
      setTransing(true) // Pulse rendering of sequences
      if (vidSrcKey) {
        setVidSrcKey('')
        vidRef.current.addEventListener('ended', onTransEnd)
      } else {
        onTransEnd()
      }
    })
    tl.addLabel('initted')
    tl.to(trans, { duration: 0.33, opacity: 0, onComplete() {
      setTransDest(null)
      tl.kill()
    } })
    tl.addLabel('finished')
    tl.tweenTo('initted')

    transTlRef.current = tl
  }

  function visitHomeFromFeature() {
    tlRef.current.kill()
    transTlRef.current.kill()
    
    const tl = gsap.timeline({ paused: true })
    transTlRef.current = tl

    function onTransEnd() {
      vidRef.current.removeEventListener('ended', onTransEnd)
      cleanup()
    }

    function cleanup() {
      history.push('/features')
      initHomeTimeline(false)
      gsap.to(transRef.current, { duration: 0.33, opacity: 0, onComplete: () => setTransDest(null) })
      tlRef.current.tweenTo('enter')
    }

    tl.to(transRef.current, { duration: 0.33, opacity: 1 })
    tl.add(() => {
      tl.kill()
      if (vidSrcKey) {
        setVidSrcKey('')
        vidRef.current.addEventListener('ended', onTransEnd)
      } else {
        cleanup()
      }
    })
    tl.play()
  }

  function cancelVisitFromHome() {
    tlRef.current.tweenTo('enter')
  }

  function visitFeatureFromHome(path) {
    // vidRef.current.src is already empty    
    const onComplete = () => {
      setHideNavLabels(true)
      setTransDest(null)
      history.push(path)
    }

    if (transTlRef.current.isActive()) { handleCancelMidTimeout() }
    tlRef.current.tweenTo("exit", { onComplete })
  }

  function handleCancelMidTimeout() {
    transTlRef.current.kill()
    gsap.to(navRef.current.querySelectorAll('.link'), { duration: 0.33, opacity: 1, stagger: 0.1 })
  }

  return(
    <div id="Features" className={changingLanguage ? 'changing-language' : ''}>
      <Switch>
        <Route path="/features" exact>
          <FeaturesHome ref={homeRef} navRef={navRef} />
        </Route>
        <Route path="/features/capture" exact>
          {!transing && <FeatureCapture />}
        </Route>
        <Route path="/features/listen" exact>
          {!transing && <FeatureListen />}
        </Route>
        <Route path="/features/share" exact>
          {!transing && <FeatureShare />}
        </Route>
        <Route path="/features/specs" exact>
          {!transing && <FeatureSpecs />}
        </Route>
        <Route path="/features/capture/gallery" exact>
          {!transing && <LifestyleCapture />}
        </Route>
        <Route path="/features/listen/gallery" exact>
          {!transing && <LifestyleListen />}
        </Route>
        <Route path="/features/share/gallery" exact>
          {!transing && <LifestyleShare />}
        </Route>
        <Route path="/features/demo" exact>
          {!transing && <Demo />}
        </Route>
      </Switch>
      <div
        className="transitioner"
        ref={transRef}
        style={{
          opacity: 0,
          display: transDest ? 'block' : 'none',
        }}></div>
      {
        isMobile
        ? <FeaturesNavMobile
            ref={navRef} 
            transTo={transTo}
            hideLabels={hideNavLabels}
            touchActive={navActive}
          />
        : <FeaturesNav
            ref={navRef} 
            transTo={transTo}
            hideLabels={hideNavLabels}
            touchActive={navActive}
          />
      }
    </div>
  )
}

export default Features