import '~/styles/FeatureShare.scss'
import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import gsap from 'gsap'
import FeatureProgressBar from '~/components/FeatureProgressBar'
import FeatureSequence from '~/components/FeatureSequence'
import useUiStore from '~/store/ui'
import useVideoStore from '~/store/video'
import useSequenceEvents from '~/hooks/useSequenceEvents'
import { splitLines } from '~/utils/splitText'
import { multiLine } from '~/utils/anims'
import { enableSequenceControls } from '~/config'

const SEGMENTS = [
  { name: 'Share-1: Tell Stories', start: 1, end: 5 },
  { name: 'Share-2: Share Photo and Video', start: 6, end: 18 },
]

const FeatureShare = () => {
  const { transDest, setAdaContent } = useUiStore(state => state)
  const [mountedSequence, setMountedSequence] = useState(false)
  const bgVid = useVideoStore(state => state.vidRef)
  const cnr = useRef()
  const progressRef = useRef()
  const tlRef = useRef()
  const c1 = useRef()
  const safeToUnmount = useRef(false)
  const { t } = useTranslation()
  const { teardown, initSequence, play, pause, back, next } = useSequenceEvents(
    tlRef,
    bgVid,
    SEGMENTS,
    initTl,
    {
      endDest: '/features/share/gallery',
      vidSrcKey: 'share',
    }
  )
  
  useEffect(() => {
    setAdaContent([{el:'h1',key:'features.share.c1.title'}])
    return handleUnmount
  }, [])

  useEffect(() => {
    if (transDest) {
      teardown()
      setAdaContent([])
      safeToUnmount.current = true
    }
  }, [transDest])
  
  function handleUnmount() {
    if (!safeToUnmount.current) {
      teardown()
      setAdaContent([])
      safeToUnmount.current = true
    }
  }

  function initTl() {
    tlRef.current = gsap.timeline({ paused: true })
    const tl = tlRef.current
    const c1Title = c1.current.querySelector('.title')
    const c1g1Desc = splitLines(c1.current.querySelector('.g1 .description'))
    const c1g2Desc = splitLines(c1.current.querySelector('.g2 .description'))
    gsap.set(progressRef.current, { scaleX: 0 })
    
    tl.add(() => setAdaContent([{el:'h1',key:'features.share.c1.title'},{el:'p',key:'features.share.c1.description'}]), SEGMENTS[0].start)
    tl.fromTo(c1Title, { opacity: 0 }, { duration: 0.5, opacity: 1 }, SEGMENTS[0].start)
    tl.fromTo(c1g1Desc, multiLine.from, multiLine.to)
    tl.to(progressRef.current, { ease: "none", duration: SEGMENTS[0].end-SEGMENTS[0].start, scaleX: 0.5 }, SEGMENTS[0].start)
    tl.addLabel("c1:in")
    tl.to(c1g1Desc, multiLine.out, SEGMENTS[0].end)
    tl.addLabel("c1:out")
    tl.add(() => setAdaContent([{el:'h1',key:'features.share.c1.title'},{el:'p',key:'features.share.c2.description'}]), SEGMENTS[1].start)
    tl.fromTo(c1g2Desc, multiLine.from, multiLine.to, SEGMENTS[1].start)
    tl.to(progressRef.current, { ease: "none", duration: SEGMENTS[1].end-SEGMENTS[1].start, scaleX: 1 }, SEGMENTS[1].start)
    tl.addLabel("c2:in")
    tl.to(c1g1Desc, multiLine.out)
    tl.addLabel("c2:out")
  }

  function init() {
    initSequence()
    setMountedSequence(true)

    return(() => {
      tlRef.current.kill()
      setMountedSequence(false)
    })
  }
  
  return(
    <>
      <FeatureSequence action={init} play={play} pause={pause} back={back} next={next} control={enableSequenceControls}>
        <div id="FeatureShare" ref={cnr} style={{opacity: mountedSequence ? 1 : 0}}>
          <div className="content-overlay c1" ref={c1}>
            <h1 className="title">{t('features.share.c1.title')}</h1>
            <div className="g1">
              <p className="description">{t('features.share.c1.description')}</p>
            </div>
            <div className="g2">
              <p className="description">{t('features.share.c2.description')}</p>
            </div>
          </div>
        </div>
      </FeatureSequence>
      <FeatureProgressBar numSegments={2} ref={progressRef} />
    </>
  )
}

export default FeatureShare