import { useState } from 'react'
import PlayButton from '~/components/PlayButton'
import ImgLazy from '~/components/ImgLazy'

const LifestyleGalleryItem = (props) => {
  const [loaded, setLoaded] = useState(false)

  return(
    <div
      className={`LifestyleGalleryItem img-cnr${props.active ? ' active' : ''}`}
      onClick={props.onClick}
    >
      <ImgLazy
        alt={props.alt}
        src={props.src}
        visible={props.visible}
        onLoad={() => setLoaded(true)}
        style={{
          transitionDelay: `${props.delay}s`
        }}
      />
      <span
        className={`overlay${props.visible && loaded ? '' : ' hide'}`}
        style={{
          transitionDelay: `${props.delay}s`
        }}
      >
        <PlayButton width="300px" height="300px" active={props.active} />
      </span>
    </div>
  )
}

export default LifestyleGalleryItem