import { useState, useEffect, useRef, useContext } from 'react'
import { enableSequenceControls } from '~/config'
import useUiStore from '~/store/ui'
import { AnalyticsContext } from '~/context/Analytics'

const FeatureSequence = ({children, action, pause, play, back, next}) => {
  const [paused, setPaused] = useState(false)
  const scrubOnRelease = useRef(false)
  const scrubTimer = useRef()
  const appBounds = useUiStore(state => state.appBounds)
  const { postInteraction } = useContext(AnalyticsContext)
  useEffect(() => {
    action()
    return () => {
      clearTimeout(scrubTimer.current)
    }
  }, [])
  
  function handlePause(e) {
    e.preventDefault()
    setPaused(true)
    pause()
  }

  function handlePlay(e) {
    e.preventDefault()
    setPaused(false)
    play()
  }

  function handlePointerDown(e) {
    scrubOnRelease.current = true
    clearTimeout(scrubTimer.current)
    scrubTimer.current = setTimeout(() => {
      scrubOnRelease.current = false
      postInteraction("videoPaused")
    }, 250)
    pause()
  }

  function handlePointerUp(e) {
    if (e.type === 'touchend') { e.preventDefault() }
    play()
    if (scrubOnRelease.current) {
      clearTimeout(scrubTimer.current)
      const clientX = e.type === 'touchend' ? e.changedTouches[0].clientX : e.clientX
      return clientX < (appBounds.w * 0.25 + appBounds.x) ? back() : next()
    }
  }

  return(
    <>
      { children }
      <div
        className="sequence-controls"
        onMouseDown={handlePointerDown}
        onMouseUp={handlePointerUp}
        onTouchStart={handlePointerDown}
        onTouchEnd={handlePointerUp}
      ></div>
      { enableSequenceControls &&
        <span
          className="feature-sequence-control"
          style={{ position: 'absolute', top: '1em', right: '1em', }}
          onClick={paused ? handlePlay : handlePause}>
          <svg width="100" height="100" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="47.5" fill="none" stroke="white" strokeWidth="5" />
            { paused
              ? <path d="M30 25 L80 50 L30 75 L30 25" strokeLinecap="round" fill="white" stroke="none" />
              : <g>
                  <rect x="30" y="25" height="50" width="10" fill="white" stroke="none" />
                  <rect x="60" y="25" height="50" width="10" fill="white" stroke="none" />
                </g> }
          </svg>
        </span> }
    </>
  )
}

export default FeatureSequence