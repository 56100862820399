import '~/styles/Attract.scss'
import { useState, useEffect, useRef, useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import gsap from 'gsap'
import LogoLockup from '~/components/LogoLockup'
import useUiStore from '~/store/ui'
import useBsStore from '~/store/bs'
import useVideoStore from '~/store/video'
import { forceBsUi, resetTimeoutDur } from '~/config'
import { AnalyticsContext } from '~/context/Analytics'
import qrCode from '~/assets/images/qr-code-web.png'
import Portal from '~/components/Portal'

const Attract = () => {
  const [mounted, setMounted] = useState(false)
  const { i18n, t } = useTranslation()
  const history = useHistory()
  const tlRef = useRef(gsap.timeline({paused: true}))
  const cnr = useRef()
  const cta = useRef()
  const setVidSrcKey = useVideoStore(state => state.setKey)
  const vidRef = useVideoStore(state => state.vidRef)
  const isBrightSign = useBsStore(state => state.isBrightSign)
  const { postInteraction, endSession, engagementStart, startEngagement } = useContext(AnalyticsContext)
  const pulseRef = useRef(new gsap.timeline({paused: true}))
  const resetTmt = useRef()
  const {
    transDest,
    setTransDest,
    setAdaContent,
    setAdaPanelOpen,
    defaultLanguage,
    adaPortalRef,
    renderAda,
    attractTimeoutDur,
    interactive,
  } = useUiStore(state => state)
  const useBsUi = isBrightSign || forceBsUi
  const touchStart = useRef(false)

  const handlePointerDown = useCallback((e) => {
    clearTimeout(resetTmt.current)
    resetTmt.current = setTimeout(() => {
      postInteraction("bounceSession")
      i18n.changeLanguage(defaultLanguage)
      setAdaPanelOpen(false)
      endSession()
    }, resetTimeoutDur)
  }, [])

  useEffect(() => {
    if (!useBsUi) { return history.push('/features') }

    const tl = tlRef.current
    const pulse = pulseRef.current
    i18n.changeLanguage(defaultLanguage)
    setVidSrcKey('attract')
    setAdaPanelOpen(false)
    initTimeline()
    setAdaContent([
      {el: 'h1', key: 'attract.title'},
    ])

    window.addEventListener("pointerdown", handlePointerDown)

    return(() => {
      tl.kill()
      pulse.kill()
      setAdaContent([])
      unbindPointerDown()
      vidRef.current.oncanplaythrough = null
      vidRef.current.onplay = null
      vidRef.current.onended = null
    })
  }, [])

  useEffect(() => {
    if (transDest) {
      exit(transDest)
    }
  }, [transDest])

  function unbindPointerDown() {
    clearTimeout(resetTmt.current)
    window.removeEventListener("pointerdown", handlePointerDown)
  }

  function onPlay(e) {
    
  }

  function onCanPlayThrough(e) {
    vidRef.current.oncanplaythrough = null
    tlRef.current.tweenTo('enter')
    e.target.play()
  }

  function onEnded(e) {
    e.target.currentTime = 0
    e.target.play()
  }

  function initTimeline() {
    const tl = tlRef.current
    const black = cnr.current.querySelector('.black')
    const logo = cnr.current.querySelector('.logo-cnr')
    const content = cnr.current.querySelector('.content')
    
    tl.add(enter, 0)
    tl.fromTo(black, { opacity: 1 }, { duration: 0.5, opacity: 0 })
    tl.fromTo(logo, { opacity: 0 }, { duration: 0.5, opacity: 1 })
    tl.fromTo(content, { opacity: 0 }, { duration: 0.5, opacity: 1 }, "-=0.5")
    tl.addLabel('enter')
    const current = tl.duration()
    tl.to(content, { duration: 0.5, opacity: 0 }, current)
    tl.to(logo, { duration: 0.5, opacity: 0 }, current)
    tl.to(black, { duration: 0.5, opacity: 1 })
    tl.add(() => {
      postInteraction("attractLoopEnd")
      startEngagement()
    })
    tl.addLabel('exit')
    setMounted(true)
    vidRef.current.oncanplaythrough = onCanPlayThrough
    vidRef.current.onplay = onPlay
    vidRef.current.onended = onEnded
  }

  function enter() {
    if (engagementStart.current) {
      const engagementLength = ((+new Date) - engagementStart.current)
      postInteraction(engagementLength > attractTimeoutDur + 5000 ? "engagedSession" : "bounceSession")
      postInteraction("attractLoopStart")
      endSession()
    }
  }

  function exit(dest) {
    unbindPointerDown()
    vidRef.current.oncanplaythrough = null // Prevent play from overriding aggro user

    function onTransEnd() {
      setTransDest(null)
      history.push(dest)
      vidRef.current.removeEventListener('ended', onTransEnd)
    }

    tlRef.current.tweenTo('exit', { onComplete() {
      setVidSrcKey('')
      vidRef.current.addEventListener('ended', onTransEnd)
    } })
  }

  function touchToStart() {
    setTransDest('/features')
  }

  return(
    <>
      <div
        id="Attract" ref={cnr}
        onClick={() => {
          if (!interactive) { return }
          if (touchStart.current) { return }
          touchToStart()
        }}
        onTouchStart={() => {
          if (!interactive) { return }
          touchStart.current = true
          touchToStart()
        }}>
        <main style={{opacity: mounted && interactive ? 1 : 0}}>
          <div className="content">
            <h2 ref={cta} className="cta">{t(`attract.cta${process.env.DIST === "ce" ? '.ce' : ''}`)}</h2>
            { process.env.DIST === "ce" &&
            <span className="qr-cnr">
              <img alt="https://raybanstoriesretailapp.com" src={qrCode} />
            </span> }
          </div>
        </main>
        <span className="black" style={{background: '#000', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,}} />
      </div>
      { renderAda && adaPortalRef &&
        <Portal rootNode={adaPortalRef.current}>
          <span onClick={touchToStart}>
            { t('attract.cta') }
          </span>
        </Portal> }
    </>
  )
}

export default Attract