const multiLine = {
  from: {
    transformOrigin: "50% 0%", opacity: 0, scaleY: 0.8, y: -20,
  },
  to: {
    duration: 0.33, opacity: 1, scaleY: 1, y: 0, ease: "power1.out", stagger: 0.1,
  },
  out: {
    duration: 0.33, transformOrigin: "50% 100%", opacity: 0, scaleY: 0.9, y: 10, ease: "power1.out", stagger: -0.1,
  },
  outTB: {
    duration: 0.25, transformOrigin: "50% 100%", opacity: 0, scaleY: 0.9, y: 10, ease: "power1.out", stagger: 0.1,
  },
}

export {
  multiLine
}