import { useEffect } from 'react'
import LifestyleGallery from '~/components/LifestyleGallery'
import useUiStore from '~/store/ui'
import coverPhone from 'IMAGE_LifestylePhone_Preview'
import coverLongboard from 'IMAGE_LifestyleLongboard_Preview'

const LifestyleListen = () => {
  const setAdaContent = useUiStore(state => state.setAdaContent)

  const vidSrcKeys = ['lifestyle-longboard', 'lifestyle-phone']
  const analyticsKeys = ['Listen-4: Lifestyle-longboard', 'Listen-5: Lifestyle-phone']
  const imgSrcs = [coverLongboard, coverPhone]
  
  useEffect(() => {
    setAdaContent([{el:'h1',key:'features.listen.tag'}])
  }, [])

  return(
    <div id="LifestyleListen">
      <LifestyleGallery
        vidSrcKeys={vidSrcKeys}
        analyticsKeys={analyticsKeys}
        rootPath={'/features/listen/gallery'}
        imgSrcs={imgSrcs}
        template={`
          "a" 50%
          "b" 50%
        `}
      >
      </LifestyleGallery>
    </div>
  )
}

export default LifestyleListen