import SplitText from 'gsap/SplitText'

function splitLines(node) {
  return new SplitText(node, {type: "lines"}).lines
  
}

function splitChars(node) {
  return new SplitText(node, {type: "chars"})
}

function splitWords(node) {
  return new SplitText(node, {type: "words"}).words
}

export {
  splitLines,
  splitChars,
  splitWords,
}