import create from 'zustand'

// Log every time state is changed
const log = config => (set, get, api) => config(args => {
  console.log("  applying", args)
  set(args)
  console.log("  new state", get())
}, get, api)

const debug = process.env.NODE_ENV === "development" && false

export default storeFn => create(debug ? log(storeFn) : storeFn)