import { useEffect } from 'react'
import useUiStore from '~/store/ui'
import LifestyleSingle from '~/components/LifestyleSingle'

const LifestyleShare = () => {
  const setAdaContent = useUiStore(state => state.setAdaContent)
  
  useEffect(() => {
    setAdaContent([{el:'h1', key:'features.share.tag'}])
  }, [])

  return(
    <>
      <LifestyleSingle vidSrcKey={'lifestyle-dog'} analyticsKey={'Share-3: Lifestyle-dog'} />
    </>
  )
}

export default LifestyleShare