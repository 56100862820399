import { useState, useEffect, useRef } from 'react'

const MultiTapButton = ({ tapsRequired, action, styles, className, timeout=3000 }) => {
  const [numTaps, setNumTaps] = useState(0)
  const tmtRef = useRef()

  useEffect(() => {
    clearTimeout(tmtRef.current)
    if (numTaps) {
      tmtRef.current = setTimeout(() => setNumTaps(0), timeout)
    }
  }, [numTaps])

  function tap(e) {
    e.stopPropagation()
    if (numTaps < tapsRequired - 1) {
      setNumTaps(curr => curr+1)
    } else {
      setNumTaps(0)
      action()
    }
  }

  return(
    <span
      className={`hidden-button${className ? className : ''}`}
      onClick={tap}
      onTouchStart={tap}
      onTouchEnd={e => e.preventDefault()}
      style={styles}
    />
  )
}

export default MultiTapButton