import '~/styles/ADAPanel.scss'
import { useRef, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AnalyticsContext } from '~/context/Analytics'
import useUiStore from '~/store/ui'

const NAV_LOOP = [
  '/features',
  '/features/capture',
  '/features/capture/gallery',
  '/features/share',
  '/features/share/gallery',
  '/features/listen',
  '/features/listen/gallery',
  '/features/specs',
]

const ADAPanel = () => {
  const portalRef = useRef()
  const setAdaPortalRef = useUiStore(state => state.setAdaPortalRef)
  const adaPanelOpen = useUiStore(state => state.adaPanelOpen)
  const setAdaPanelOpen = useUiStore(state => state.setAdaPanelOpen)
  const setTransDest = useUiStore(state => state.setTransDest)
  const highlight = useUiStore(state => state.highlight)
  const adaContent = useUiStore(state => state.adaContent)
  const setChangingLanguage = useUiStore(state => state.setChangingLanguage)
  const frenchDialect = useUiStore(state => state.frenchDialect)
  const { t, i18n } = useTranslation()
  const path = useLocation().pathname
  const { postInteraction, forceCurrentPageLoad } = useContext(AnalyticsContext)

  useEffect(() => {
    setAdaPortalRef(portalRef)
  }, [])

  useEffect(() => {
    if (adaPanelOpen) { postInteraction("modalLoad", { modalName: "ADA" }) }
  }, [adaPanelOpen])

  function changeLang(to) {
    setChangingLanguage(true)
    .then(() => {
      i18n.changeLanguage(to)
      return
    })
    .then(() => {
      setChangingLanguage(false)
    })
  }

  function computeCurrent(name) {
    if (highlight) {
      return !!highlight.match(name)
    } else {
      return !!path.match(name)
    }
  }

  function navNext() {
    const curr = NAV_LOOP.findIndex(item => item === path)
    const dest = NAV_LOOP[(curr + 1)%NAV_LOOP.length]
    setTransDest(dest)
  }
  
  function navBack() {
    const curr = NAV_LOOP.findIndex(item => item === path)
    const dest = NAV_LOOP[curr - 1 >= 0 ? curr - 1 : NAV_LOOP.length - 1]
    setTransDest(dest)
  }

  function handleClick(navName, path, regExp) {
    postInteraction("navClick", { navName, navType: "ADA Nav" })
    if (computeCurrent(regExp)) { forceCurrentPageLoad() }
    return setTransDest(path)
  }

  return(
    <div id="ADAPanel" className={`${adaPanelOpen ? 'active' : ''}`}>
      <span className="escape" onClick={_ => setAdaPanelOpen(false)}>
        <svg width="100" height="100" viewBox="0 0 100 100">
          <path d="M5 5 L95 95" stroke="white" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5 95 L95 5" stroke="white" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </span>
      <div className="language">
        <button className={i18n.language === 'en' ? 'active' : ''} onClick={_ => changeLang('en')}>EN</button>
        <button className={i18n.language === 'es' ? 'active' : ''} onClick={_ => changeLang('es')}>ES</button>
        <button className={i18n.language === frenchDialect ? 'active' : ''} onClick={_ => changeLang(frenchDialect)}>FR</button>
        <button className={i18n.language === 'it' ? 'active' : ''} onClick={_ => changeLang('it')}>IT</button>
        <span>{t('ada.label.language')}</span>
      </div>
      <div className="navigation">
        <div className="back" onClick={() => {
          postInteraction("navClick", {navName: "Prev", navType: "ADA Nav"})
          return navBack()
        }}>
          <svg width="100" height="150" viewBox="0 0 100 150"><path d="M95 5 L5 75 L95 145" stroke="white" fill="none" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round"/></svg>
          <span>{t('ada.label.back')}</span>
        </div>
        <div className="links">
          <span
            className={`link${computeCurrent(/\/features$/) ? ' current' : ''}`}
            onClick={() => handleClick("Home", "/features", /\/features$/)}
          >
            {t('features.nav.home')}
          </span>
          <span
            className={`link${computeCurrent('capture') ? ' current' : ''}`}
            onClick={() => handleClick("Capture", "/features/capture", 'capture')}
          >
            {t('features.nav.capture')}
          </span>
          <span
            className={`link${computeCurrent('share') ? ' current' : ''}`}
            onClick={() => handleClick("Share", "/features/share", 'share')}
          >
            {t('features.nav.share')}
          </span>
          <span
            className={`link${computeCurrent('listen') ? ' current' : ''}`}
            onClick={() => handleClick("Listen", "/features/listen", 'listen')}
          >
            {t('features.nav.listen')}
          </span>
          <span
            className={`link${computeCurrent('specs') ? ' current' : ''}`}
            onClick={() => handleClick("Tech", "/features/specs", 'specs')}
          >
            {t('features.nav.specs')}
          </span>
        </div>
        <div className="fwd" onClick={() => {
          postInteraction("navClick", {navName: "Next", navType: "ADA Nav"})
          return navNext()
        }}>
          <svg width="100" height="150" viewBox="0 0 100 150"><path d="M5 5 L90 75 L5 145" stroke="white" fill="none" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round"/></svg>
          <span>{t('ada.label.forward')}</span>
        </div>
      </div>
      <div className="content">
        {
          adaContent.map((item, i) => {
            const Component = item.el
            return <Component key={i} dangerouslySetInnerHTML={{__html: t(item.key).replaceAll(/<\/?[1-9]>/g, (item.break||""))}}/>
          })
        }
        <div id="ada-portal" ref={portalRef}></div>
      </div>
    </div>
  )
}

export default ADAPanel